import type { SanityImageObject } from '@sanity/image-url/lib/types/types';

import { toIdSlug } from '~/lib/toIdSlug';

// This interface describes the props needed to generate a `TopicPreview`
export interface PreviewableSanityTopic {
  featuredImage?: SanityImageObject | null;
  id: string;
  isFeatured: boolean;
  name: string;
  slug: string;
}

export interface TopicPreview {
  featuredImageObject?: SanityImageObject;
  idSlug: string;
  isFeatured?: boolean;
  name: string;
  slug: string;
}

/**
 * @description Return an object usable in `TopicCard`
 */
export function sanityTopicToTopicPreview(sanityResource: PreviewableSanityTopic): TopicPreview {
  const { featuredImage, id, isFeatured = false, name, slug } = sanityResource;

  return {
    ...(featuredImage ? { featuredImageObject: featuredImage } : {}),
    idSlug: toIdSlug(id),
    isFeatured,
    name,
    slug,
  };
}
